<template>
  <div id="FakeDigitalSite" class="">
    <div class="wrap">
      <div class="f-select s01" @click="showselect(1)">
        <div class="s-t">模型名称</div>
        <div class="fs-text">
          <div class="text-c">
            {{ value01.text }}
          </div>
          <img src="@/assets/img/i13.png" alt="" class="icon-s" />
        </div>
      </div>
      <div class="obv-wrap">
        <img
          v-if="imgdata&&imgdata.FileType == 2"
          class="imgdata"
          :src="baseUrl + imgdata.ImgUrl"
          alt=""
        />
        <obvCompIndex
          ref="obvCompIndex"
          
          @loaded="loaded"
        ></obvCompIndex>
      </div>

      <div class="content-type">
        <div class="content-type-header">设备监控（{{ num ? num : 0 }}）</div>
        <div v-if="datalist.length">
          <div
            @click="detail(item)"
            class="content-type-list"
            v-for="(item, index) in datalist"
            :key="index"
          >
            <div class="list-system">
              <div class="list-system-image" v-if="item.deviceType == 6">
                <img src="@/assets/img/dg01.png" alt="" class="dg01" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 3">
                <img src="@/assets/img/dg02.png" alt="" class="dg02" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 4">
                <img src="@/assets/img/dg03.png" alt="" class="dg03" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 5">
                <img src="@/assets/img/dg04.png" alt="" class="dg04" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 1">
                <img src="@/assets/img/dg05.png" alt="" class="dg05" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 2">
                <img src="@/assets/img/dg06.png" alt="" class="dg06" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 8">
                <img src="@/assets/img/dg07.png" alt="" class="dg07" />
              </div>
              <div class="list-system-image" v-if="item.deviceType == 9">
                <img src="@/assets/img/dg08.png" alt="" class="dg08" />
              </div>
              <div class="">{{ item.deviceTypeName }}</div>
            </div>
            <div class="list-number">
              <div>{{ item.deviceList.length }}</div>
              <img src="@/assets/img/dg-r.png" alt="" />
            </div>
          </div>
        </div>
        <div class="nodata" v-if="flags == true">暂无数据</div>
      </div>
      <van-picker
        v-if="pshow && columns && columns.length"
        title="选择模型"
        show-toolbar
        :columns="columns"
        :default-index="defaultindex"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </div>
    <div class="navlist-c">
      <div
        class="one-bar-c"
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: index == activeindex }"
        @click="changepath(item, index)"
      >
        <div class="icon-c">
          <img :src="item.icon" v-if="index != activeindex" />
          <img :src="item.iconl" v-if="index == activeindex" />
        </div>
        <div class="text-c">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import { GetWeChatDeviceList, GetBIMlList } from "@/js/firstApi";
import obvCompIndex from "@/components/obvs/obvViewIndex";
import { baseUrl } from "@/js/util";
import { Picker, Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  name: "FakeDigitalSite",
  data() {
    return {
      urn: null, // 模型的 urn
      value01: { text: "请选择楼号" },
      baseUrl: baseUrl,
      columns: [],
      columns01: [],
      columns02: [],
      datalist: [],
      pshow: false,
      selectactiveindex: "",
      defaultindex: 0,
      num: "",
      flags: "",
      list: [
        {
          path: "/pages/haveCharts/pages/indexv2/index",
          icon: require("@/assets/img/nav01.png"),
          iconl: require("@/assets/img/nav01l.png"),
          text: "项目进展",
        },
        {
          path: "/pages/haveCharts/pages/digitization/homePage/index",
          icon: require("@/assets/img/nav02.png"),
          iconl: require("@/assets/img/nav02l.png"),
          text: "数字工地",
        },
        {
          path: "/pages/workBench/index",
          icon: require("@/assets/img/nav03.png"),
          iconl: require("@/assets/img/nav03l.png"),
          text: "工作台",
        },
        {
          path: "/pages/my/index",
          icon: require("@/assets/img/nav04.png"),
          iconl: require("@/assets/img/nav04l.png"),
          text: "我的",
        },
      ],
      activeindex: 1,
      token: "",
      pid: "",
      uid: "",
      imgdata: "",
    };
  },
  components: {
    obvCompIndex,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
  },
  computed: {},
  mounted() {
    let query = this.$route.query;
    console.log(query);
    if (query) {
      if (query.token) {
        this.token = query.token;
      }
      if (query.pid) {
        this.pid = query.pid;
      }
      if (query.uid) {
        this.uid = query.uid;
      }

      // this.$nextTick(() => {
      //   this.$refs.obvCompIndex.modelInit(['urn:bimbox.object:SmartConstructionSite/ObjectKey_1649643067277'], "");
      // });
      console.log(175, 1111);
      this.GetBIMlList();
      this.GetWeChatDeviceList();
    }
  },
  methods: {
    showselect(selectactiveindex) {
      // 点击弹出 picke 弹框
      this.selectactiveindex = selectactiveindex;
      if (selectactiveindex == 1) {
        this.columns = this.columns01.concat();
      } else if (selectactiveindex == 2) {
        this.columns = this.columns02.concat();
      }
      this.pshow = true;
    },
    onConfirm(value, index) {
      console.log(value, index, 192);
      this.imgdata = value;
       if (this.imgdata.FileType == 1) {
     
          this.$nextTick(() => {
            this.$refs.obvCompIndex.modelInit([this.imgdata.ImgUrl], "");
          });
          // if (this.urn) {
          //   this.$refs.obvCompIndex.unnitObv();
          // }
        } else if (this.imgdata.FileType == 2) {
        }
      // picke 点击确认
      this.pshow = false;
      this.defaultindex = index;
      if (this.selectactiveindex == 1) {
        console.log(value, index);
        this.value02 = { text: "请选择楼层" };
        this.value01 = value;
        this.selectedfloor = "";
        this.selectedcomponentdata = "";
        // this.getmodelData();
       
      } else if (this.selectactiveindex == 2) {
        this.value02 = value;
        console.log(this.value02);
        if (this.value02.text == "显示全部") {
          obvApiOne.showAll();
          this.selectedcomponentdata = "";
          this.selectedfloor = "";
        } else {
          obvApiOne.hideAll();
          obvApiOne.show([
            {
              dbId: this.value02.ComponentId,
              modelId: obvApiOne.getModelIds()[0],
            },
          ]);
          this.selectedcomponentdata = "";
          this.selectedfloor = this.value02.text;
        }
      }
    },
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`);
      // picke 改变（滑动）
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      // pick 点击取消
      this.pshow = false;
    },
    GetBIMlList() {
      console.log(232, "模型");
      // 获取模型列表
      GetBIMlList({ TypeID: 2, ProjectID: this.pid }, this.token)
        .then((res) => {
          console.log(res, 235);
          let { Code, Data, Message } = { ...res.data };
          if (Code == 1 && Data) {
            Data.forEach((v) => {
              v.text = v.Name;
            });
            this.columns01 = Data;
            this.value01 = Data[0];
            this.urn = this.value01.ImgUrl;
            this.imgdata = Data[0];
          
            if (Data[0].FileType == 1) {
             
              this.$nextTick(() => {
                this.$refs.obvCompIndex.modelInit([Data[0].ImgUrl], "");
              });
            } else if (Data[0].FileType == 2) {
              
            }
          } else {
            console.log(Message);
          }
        })
        .catch((error) => {});
    },
    //     getmodelData() {
    //   // 获取模型相关数据
    //   Toast.loading({
    //     duration: 0, // 持续展示 toast
    //     forbidClick: true,
    //     message: "模型数据获取中……",
    //   });
    //   if (this.value01.ImgUrl) {
    //           this.urn =this.value01.ImgUrl;
    //           this.$nextTick(() => {
    //             this.$refs.obvCompIndex.modelInit([this.value01.ImgUrl], "");
    //           });
    //         }

    //       Toast.clear();

    // },
    loaded(modelId) {
      // this.GetDataList(modelId);
      // this.GetModelList(this.value01.ID,modelId);
    },
    GetWeChatDeviceList() {
      // 数字工地设备列表
      GetWeChatDeviceList({ ProjectID: this.pid }, this.token)
        .then((res) => {
          console.log(res);
          let { Code, Data, Message } = { ...res.data };
          if (Code == 1 && Data) {
            let list = [];
            let count = 0;
            Data.result.forEach((v) => {
              if (v.deviceList && v.deviceList.length) {
              
                count += v.deviceList.length;
                list.push(v);
              }
            });
            this.datalist = list;
            this.num = count;
          } else {
            this.flags = true;
            console.log(Message);
          }
        })
        .catch((error) => {
          this.flags = true;
        });
    },
    getmodelData() {
      console.log(this.imgdata, 315);
      if (this.imgdata.FileType == 1) {

        // this.$nextTick(() => {
        //   this.$refs.obvCompIndex.modelInit([this.imgdata.ImgUrl], "");
        // });
        if (this.urn) {
          this.$refs.obvCompIndex.unnitObv();
        }
      } else if (this.imgdata.FileType == 2) {
      }

      // 获取模型相关数据
      // Toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: "模型数据获取中……",
      // });

      // if (this.urn) {
      //   this.$refs.obvCompIndex.unnitObv();
      // }

      // Toast.clear();

      // }
    },
    detail(item) {
      let data = item;
      let objstr = encodeURIComponent(JSON.stringify(data)); //函数可把字符串作为URI组件进行编码
      console.log(data);
      let urls = "";
      //1标准养护检测 2环境监测 3视频监控 4吊钩可视化 5智能水表 6智能电表 7塔机检测系统 8人脸识别系统 9料台系统 10升降电梯 [原]

      //1标准养护检测 2环境监测 3视频监控  4智能水表 5智能电表 6塔机检测系统 7人脸识别系统 8料台系统 9升降电梯 [新]
      if (data.deviceType == 6) {
        //塔机
        urls =
          "/pages/haveCharts/pages/digitization/towers/towerhome/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 4 || data.deviceType == 5) {
        urls =
          "/pages/haveCharts/pages/digitization/water/waterdetail/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 2) {
        urls =
          "/pages/haveCharts/pages/digitization/raise/raisedetail/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 1) {
        urls =
          "/pages/haveCharts/pages/digitization/room/roomdetail/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 8) {
        urls =
          "/pages/haveCharts/pages/digitization/discharge/dischargedetail/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 9) {
        urls =
          "/pages/haveCharts/pages/digitization/fall/fallhome/index?pagedata=" +
          objstr;
      } else if (data.deviceType == 3) {
        urls = "/pages/videolist/index?pagedata=" + objstr;
      }
      wx.miniProgram.navigateTo({
        url: urls,
         success: (res) => {
        console.log(res,'页面跳转成功');   // 页面跳转成功的回调函数
      },
      fail: (err) => {
        console.log(err,'页面跳转失败');   // 页面跳转失败的回调函数
      }
      });
    },
    changepath: function (item, index) {
      if (index != this.activeindex) {
        wx.miniProgram.postMessage({
          data: JSON.stringify({ index: index }),
        });
        wx.miniProgram.redirectTo({
          url: item.path,
        });
        app.globalData.navindex = index;
        let localdata = JSON.parse(wx.getStorageSync("localdata"));
        localdata.navindex = index;
        wx.setStorageSync("localdata", JSON.stringify(localdata));
        wx.redirectTo({
          url: item.path,
        });
      }
    },
    loaded(modelId) {},
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#FakeDigitalSite {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box;
  background: #f7f7f7;
  /deep/ {
    #v3d-viewer {
      display: none;
    }
  }
  .f-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1rem;
    height: 0.4rem;
    padding: 0 0.1rem;
    background: #fff;
    &.s02 {
      border-bottom: 1px solid #f7f7f7;
      .fs-text {
        color: #333333;
      }
    }
    &.s03 {
      border-bottom: 1px solid #f7f7f7;

      .fs-text {
        color: #333333;
      }
    }
    &.s04 {
      border-bottom: none;
      padding: 0;
    }
    .s-t {
      font-size: 0.14rem;
      color: #333333;
    }
    .fs-text {
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      color: #0083ff;
      .status1 {
        color: #2ace5c;
      }
      .status2 {
        color: #8d928e;
      }
      .status3 {
        color: #ce432a;
      }
    }
    .icon-s {
      width: 0.2rem;
      height: 0.2rem;
      object-fit: contain;
      transform: rotate(-90deg);
    }
  }
  .wrap {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 0.45rem;
    overflow-y: auto;
    .des-c {
      // margin: 8px 0 0;
      padding: 0 0.1rem;
      .van-cell {
        border: 1px solid #ddd;
        border-radius: 3px;
        background: #f3f3f3;
        font-size: 0.14rem;
        color: #333333;
      }
    }
    .obv-wrap {
      width: 100%;
      height: 3rem;
      position: relative;
      .imgdata {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
      }
    }
    .component-selected {
      font-size: 0.12rem;
      line-height: 0.2rem;
      color: red;
      margin: 10px 0 0;
      padding: 0 0.26rem;
      position: relative;
      .icon-f {
        color: #fff;
        background: red;
        width: 0.13rem;
        height: 0.13rem;
        line-height: 0.13rem;
        text-align: center;
        position: absolute;
        left: 0.1rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
      span {
        padding: 0 10px 0 0;
      }
    }
  }
  .nodata {
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    font-size: 14px;
    color: #666;
  }
  /deep/ {
    #v3d-diver {
      display: none;
    }
  }
  .obv-wrap {
    width: 100%;
    height: 3rem;
    position: relative;
  }
  .content-type {
    background: #fff;
    margin: 0.1rem 0 0;
    position: relative;
  }

  .content-type-header {
    width: 100%;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    padding: 0 0.1rem;
    box-sizing: border-box;
  }

  .content-type-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.45rem;
    line-height: 0.45rem;
    border-top: 1px solid RGBA(247, 247, 247, 1);
  }

  .list-system {
    width: 100%;
    height: 0.45rem;
    line-height: 0.45rem;
    border-top: 1px solid RGBA(247, 247, 247, 1);
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .list-system-image {
    width: 0.41rem;
    height: 0.45rem;
    text-align: center;
  }

  .dg01 {
    width: 0.2rem;
    height: 0.2rem;
    vertical-align: text-bottom;
  }

  .dg02 {
    width: 0.2rem;
    height: 0.22rem;
    vertical-align: text-bottom;
  }

  .dg03 {
    width: 0.17rem;
    height: 0.22rem;
    vertical-align: text-bottom;
  }

  .dg04 {
    width: 0.12rem;
    height: 0.23rem;
    vertical-align: text-bottom;
  }

  .dg05 {
    width: 0.21rem;
    height: 0.15rem;
    vertical-align: text-bottom;
  }

  .dg06 {
    width: 0.22rem;
    height: 0.22rem;
    vertical-align: text-bottom;
  }

  .dg07 {
    width: 0.26rem;
    height: 0.15rem;
    vertical-align: text-bottom;
  }

  .dg08 {
    width: 0.21rem;
    height: 0.18rem;
    vertical-align: text-bottom;
  }

  .list-number {
    display: flex;
    align-items: center;
  }

  .list-number div {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }

  .list-number img {
    width: 0.07rem;
    height: 0.12rem;
    margin: 0 0.14rem 0 0.15rem;
    box-sizing: border-box;
    object-fit: cover;
  }
  .navlist-c {
    display: flex;
    height: 0.5rem;
    box-shadow: 10px 10px 15px #000;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .one-bar-c {
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: 0.07rem 0 0;
  }
  .icon-c {
    width: 0.23rem;
    height: 0.23rem;
    margin: 0 auto;
    position: relative;
  }
  .icon-c img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .text-c {
    font-size: 0.1rem;
    color: #c6c6c6;
    line-height: 0.14rem;
    height: 0.14rem;
    margin: 0.02rem 0 0;
  }
  .active .text-c {
    color: #00a5ff;
  }
  .van-picker {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>